export const triggerPhoneConversion = () => {
    // window.gtag('event', 'conversion', {
    //     'send_to': 'AW-10838573329/geOzCOGXn6EDEJGCnrAo',
    // });
}

export const addSlashIfNotAbsoluteUrl = (url) => {
    if (url.indexOf('http') === 0) {
        return url;

    }

    if (url[0] === '/') {
        return url;
    }

    return '/' + url;
}