import {storyblokEditable, useStoryblokApi} from "@storyblok/react";
import classNames from "classnames";
import ButtonGroup from "../partials/ButtonGroup";
import {richTextResolver} from "@storyblok/richtext";

export default function TwoColumnContentSection(props: { blok: any }) {
    const { render } = richTextResolver()
    const blok = props.blok;
    const buttons = <ButtonGroup buttons={blok.buttons}/>

    return (
        <div className={"overflow-hidden " + blok.class + " "} {...storyblokEditable(blok)}>
            <div className={"mx-auto space-y-8 px-4 sm:px-6 lg:px-8 " + blok?.container_size}>

                <div className="mx-auto max-w-prose text-base lg:max-w-none">
                    {blok.subtitle &&
                        <h2 className="text-lg font-semibold text-secondary">
                            {blok.subtitle}
                        </h2>
                    }
                    {blok.title &&
                        <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            {blok.title}
                        </p>}
                </div>
                {blok.intro &&
                    <div className="relative z-10 mx-auto max-w-prose text-base lg:mx-0 lg:max-w-5xl lg:pr-72">
                        <p className="prose lg:text-lg text-gray-700">
                            {blok.intro}
                        </p>
                    </div>}

                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-14">
                    <div className={classNames({"relative z-10": true})}>

                        <div
                            className="prose text-justify mx-auto text-gray-700 lg:max-w-none">
                            <div className=""
                                 dangerouslySetInnerHTML={{
                                     __html: render(blok.text) as string
                                 }}
                            >
                            </div>
                        </div>
                        {blok.buttons && blok.buttons.length > 0 &&
                        <div className="mx-auto mt-10 flex max-w-prose text-base lg:max-w-none gap-4">
                            {buttons}
                        </div>}
                    </div>
                    <div className="relative mx-auto max-w-prose text-base lg:mt-0 lg:max-w-none">
                        {!blok.hide_pattern &&
                            <svg
                                className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                              fill="currentColor"/>
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"/>
                            </svg>}

                        <div
                            className="prose mx-auto text-gray-700 lg:max-w-none ">
                            <div className=""
                                 dangerouslySetInnerHTML={{
                                     __html: render(blok.text_right) as string
                                 }}
                            >
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
