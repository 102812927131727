import React, {Fragment, PropsWithChildren, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {CalendarIcon} from '@heroicons/react/24/outline'

export default function ScheduleAppointment({
                                                buttonElement,
                                                classNames
                                            }: { buttonElement: any, classNames: string } & PropsWithChildren) {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        let timeout: string | number | NodeJS.Timeout | undefined;

        if (open === true) {
            if (typeof window !== 'undefined') {
                timeout = setTimeout(() => {
                    // @ts-ignore
                    // window.gtag('event', 'conversion', {'send_to': 'AW-10838573329/lTQ4CI73qZ8DEJGCnrAo'});
                }, 30000)
            }
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [open]);

    const openScheduler = () => {
        if(window.innerWidth < 640) {
            window.open('https://calendly.com/pacificcodeline/initial-consultation?embed_domain=pacificcodeline.com&embed_type=Inline&primary_color=333747', '_blank')
            return;

        }
        setOpen(true)
    }

    //
    return (<>
            <button
                onClick={openScheduler}
                className={classNames}
            >
                {buttonElement.title}

                <span
                    dangerouslySetInnerHTML={{
                        __html: (buttonElement.icon)
                    }}
                />
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-50 overflow-y-auto px-6">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <div>
                                        <div
                                            className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                            <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3"
                                                          className="text-base font-semibold leading-6 text-gray-900">
                                                Book an appointment with us!
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className={'text-gray-700'}>
                                                    We are excited to work with you!
                                                    Book a free, non-binding initial consultation with us.
                                                </p>
                                                <p>
                                                    Do you prefer to call us? <a className={'link'}
                                                                                 href={'tel:+19495412305'}>
                                                    (949) 541-2305
                                                </a>
                                                </p>

                                                <iframe
                                                    src={'https://calendly.com/pacificcodeline/initial-consultation?embed_domain=pacificcodeline.com&embed_type=Inline&primary_color=333747'}
                                                    className={'h-[700px] w-full max-h-screen'}
                                                    width="100%" height="100%" frameBorder="0"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md btn-text px-3 py-2"
                                            onClick={() => {
                                                if (confirm('Are you sure you want to cancel?')) setOpen(false)
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
