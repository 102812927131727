import Link from "next/link";
import classNames from "classnames";
import {Fragment} from "react";
import {storyblokEditable} from "@storyblok/react";
import ScheduleAppointment from "../functional/ScheduleAppointment";
import {addSlashIfNotAbsoluteUrl} from "../../utils/Helpers";

export default function ButtonGroup({buttons}: { buttons: Array<any> }) {
    let x = 0;
    return <Fragment>
        {buttons?.map((el: any) => {
            x++;
            const button = () => {
                if (el.special_action === 'schedule') {
                    return <ScheduleAppointment
                        key={`${el._uid}-button-${x}`}
                        buttonElement={el}
                        classNames={classNames(['flex w-full items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium', 'rounded-md shadow', el.class])}>
                        {el.title}

                        <span
                            dangerouslySetInnerHTML={{
                                __html: (el.icon)
                            }}
                        />
                    </ScheduleAppointment>
                }

                if (el.special_action === 'call') {
                    return <a
                        key={`${el._uid}-button-${x}`}
                        href={`tel:+19495412305`}
                        className={classNames(['flex w-full items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium', 'rounded-md shadow', el.class])}>
                        {el.title}

                        <span
                            dangerouslySetInnerHTML={{
                                __html: (el.icon)
                            }}
                        />
                    </a>
                }

                if (el.special_action === 'form') {
                    return <a
                        key={`${el._uid}-button-${x}`}
                        href={`/contact`}
                        className={classNames(['flex w-full items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium', 'rounded-md shadow', el.class])}>
                        {el.title}

                        <span
                            dangerouslySetInnerHTML={{
                                __html: (el.icon)
                            }}
                        />
                    </a>
                }

                return <Link
                    key={`${el._uid}-button-${x}`}
                    href={addSlashIfNotAbsoluteUrl(el.link.cached_url)}
                    target={el.link?.cached_url?.includes('http') ? '_blank' : '_self'}
                    className={classNames([ 'flex w-full items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium', 'rounded-md shadow', el.class])}
                >
                    {el.title}

                    <span
                        dangerouslySetInnerHTML={{
                            __html: (el.icon)
                        }}
                    />
                </Link>
            }

            return <div key={`${el._uid}-buttondiv-${x}`} className=""  {...storyblokEditable(el)}>
                {button()}
            </div>
        })}
    </Fragment>
}